import Vue from 'vue'
import VueRouter from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'

import Meta from 'vue-meta'
Vue.use(Meta)

Vue.use(VueRouter)
component: () => import('../views/index')


const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title:"Karukaj DIGITAL", metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    },
    component: () => import('../views/index')
  },
  {
    path: '/workstation',
    name: 'workstation',
    meta: {
      title:"Network", metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    },
    component: () => import('../views/workstation')
  },
  
  {
    path: '/index2',
    name: 'index2',
    meta: {
      title:"Karukaj DIGITAL HOME",
    },
    component: () => import('../views/index2')
  },
  {
    path: '/explore',
    name: 'explore', meta: {  title:"Karukaj DIGITAL Marketing Agency |", 
    metaTags: [
      {
        name: 'description',
        content: 'The home page of our example app.'
      },
      {
        property: 'og:description',
        content: 'The home page of our example app.'
      }
    ]
   },
    component: () => import('../views/explore')
  },
 
 
  {    path: '/about',
    name: 'about',
    meta: {
      title:"Karukaj About Us",
    },
    component: () => import('../views/about'), 
    
  },



  {
    path: '/company',
    name: 'company',
    meta: {
      title:"Karukaj Company Info", 
      meta: [
        { name: 'description', content:  'Karukaj is an independent and innovative full-service digital marketing agency that helps startups & midsize companies scale their growth.'},
        { property: 'og:title', content: "Karukaj - Build your network in your field of interest"},
        { property: 'og:site_name', content: 'Karukaj'},
        {property: 'og:type', content: 'website'},    
        {name: 'robots', content: 'index,follow'} 
    ]
    },
    component: () => import('../views/company')
  },
  {
    path: '/support',
    name: 'support',
    meta: {
      title:"Support Center",
    },
    component: () => import('../views/support')
  },
  {
    path: '/knowledgebase',
    name: 'knowledgebase',
    meta: {
      title:"Knowledgebase",
    },
    component: () => import('../views/knowledgebase')
  },
  {
    path: '/ticket',
    name: 'ticket',
    meta: {
      title:"Get in Touch",
    },
    component: () => import('../views/ticket')
  },
  {
    path: '/estimate',
    name: 'estimate',
    meta: {
      title:"Estimate Request",
    },
    component: () => import('../views/estimate')
  },
  {
    path: '/terms',
    name: 'terms',
    meta: {
      title:"Tarms and Condition",
    },
    component: () => import('../views/terms')
  },
  {
    path: '/privacy',
    name: 'privacy',
    meta: {
      title:"Karukaj Home",
    },
    component: () => import('../views/privacy')
  },
  {
    path: '/refund',
    name: 'refund',
    meta: {
      title:"Karukaj Home",
    },
    component: () => import('../views/refund')
  },
  {
    path: '/disclosure',
    name: 'disclosure',
    meta: {
      title:"Karukaj Home",
    },
    component: () => import('../views/disclosure')
  },
  {
    path: '/tmshibli',
    name: 'tmshibli',
    meta: {
      title:"TM SHIBLI N.",
    },
    component: () => import('../views/tmshibli')
  },
  {
    path: '/blog',
    name: 'blog',
    meta: {
      title:"Blog",
    },
    component: () => import('../views/blog')
  },
  {
    path: '/signup',
    name: 'signup',
    meta: {
      title:"Signup",
    },
    component: () => import('../views/signup')
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      title:"Form",
    },
    component: () => import('../views/contact')
  },
  {
    path: '*',
    component: () => import('../views/error')

  },
]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})


  
export default router
